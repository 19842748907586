/*eslint-disable*/
import React from "react";
import Link from "next/link";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/nextjs-material-kit/components/headerLinksStyle.js";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const { t } = useTranslation('header')
  const router = useRouter()

  return (
    <List className={ classes.list }>
      <ListItem className={ classes.listItem }>
        <CustomDropdown
          noLiPadding
          navDropdown
          buttonText={ t('lang-selector') }
          buttonProps={ {
            className: classes.navLink,
            color: "transparent"
          } }
          buttonIcon={ Apps }
          dropdownList={ [
            <Link href={ router.asPath } locale="hu">
              <a className={ classes.dropdownLink }>{ t('lang-hu') }</a>
            </Link>,
            <Link href={ router.asPath } locale="en">
              <a className={ classes.dropdownLink }>
                { t('lang-en') }
              </a>
            </Link>
          ] }
        />
      </ListItem>
      <ListItem className={ classes.listItem }>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/ }
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={ "top" }
          classes={ { tooltip: classes.tooltip } }
        >
          {/*//@ts-ignore*/ }
          <Button
            href="https://twitter.com/CreativeTim?ref=creativetim"
            target="_blank"
            color="transparent"
            className={ classes.navLink }
          >
            <i className={ classes.socialIcons + " fab fa-twitter" }/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={ classes.listItem }>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={ "top" }
          classes={ { tooltip: classes.tooltip } }
        >
          {/*//@ts-ignore*/ }
          <Button
            color="transparent"
            href="https://www.facebook.com/CreativeTim?ref=creativetim"
            target="_blank"
            className={ classes.navLink }
          >
            <i className={ classes.socialIcons + " fab fa-facebook" }/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={ classes.listItem }>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={ "top" }
          classes={ { tooltip: classes.tooltip } }
        >
          {/*//@ts-ignore*/ }
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
            target="_blank"
            className={ classes.navLink }
          >
            <i className={ classes.socialIcons + " fab fa-instagram" }/>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
